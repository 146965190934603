.spreadsheet-container {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    position: relative;
}

.spreadsheet-container table {
    width: 100%;
    table-layout: fixed;
    min-width: 1000px;
    /* Ensures the table has a minimum width to enable scrolling */
}

/* Make the header row sticky */
.spreadsheet-container thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
}

/* First 4 columns (id, name, email, tax_id) - 25% each */
.spreadsheet-container th:nth-child(2),
.spreadsheet-container td:nth-child(2),
.spreadsheet-container th:nth-child(4),
.spreadsheet-container td:nth-child(4),
.spreadsheet-container th:nth-child(5),
.spreadsheet-container td:nth-child(5) {
    width: 13%;
}

.spreadsheet-container th:nth-child(3),
.spreadsheet-container td:nth-child(3) {
    width: 20%;
}

/* Last 3 columns (zip, tax_system, livemode) - 5% each */
.spreadsheet-container th:nth-child(6),
.spreadsheet-container td:nth-child(6),
.spreadsheet-container th:nth-child(7),
.spreadsheet-container td:nth-child(7) {
    width: 10%;
}

.spreadsheet-container th:nth-child(1),
.spreadsheet-container td:nth-child(1),
.spreadsheet-container th:nth-child(8),
.spreadsheet-container td:nth-child(8) {
    width: 5%;
}